import React, {useEffect} from 'react'
import { createGlobalStyle } from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby'
import { RichText, Link as PrismicLink } from 'prismic-reactjs'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { Elements } from 'prismic-richtext'
import { linkResolver } from '../gatsby/linkResolver'

import Header from '../components/header';
import Footer from '../components/footer';
import Newsletter from '../components/newsletter';

const GlobalStyleLocal = createGlobalStyle`
  .ReactModal__Overlay {
    z-index: 11;
    background-color: #fff!important;
  }
  
  .ReactModal__Content {
    width: 100%;
    left: 0!important;
    right: 0!important;
    top: 0!important;
    bottom: 0!important;
    border: 0!important;
    border-radius: 0!important;
  }

  .modal-background {
    background: #fff;
  }

  .modal-content {
    text-align: center;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        font: italic bold 30px 'Times new roman';
        color: #000000;
      }

      .close-modal {
        background: #f1a857;
        height: 50px;
        width: 83px;
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: .2s all ease;

        i {
          background: url("/images/streamline-icon-close-140-x-140.png") no-repeat center;
          background-size: contain;
          width: 24px;
          height: 24px;
        }

        &:hover {
          background: #e59134;
        }
      }
    }

    .terms-content {
      border: 1px solid #979797;
      background: #efefef;
      padding: 27px 30px 25px 24px;
      overflow-y: auto;
      max-height: 500px;
      text-align: left;

      h3 {
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 20px;
        font-weight: 700;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }

  .modal-content.modal-out {
    width: 100%;
    padding: 50px 20px;
    margin: 0 auto;

    .terms-content {
      max-height: 100%;
    }
  }

  @media (min-width: 767px) {
    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.6)!important;
    }

    .ReactModal__Content {
      max-width: 710px;
      margin: 0 auto;
      border-radius: 3px!important;
      top: 40px!important;
      bottom: 40px!important;
      padding: 30px 20px 30px 35px!important;
    }

    .modal-content.modal-out {
      max-width: 767px;

      .terms-content {
        max-height: 100%;
      }
    }

    .modal-content {
      .modal-header {
        h2 {
          font: italic bold 40px 'Times new roman';
        }
      }

      .terms-content {
        max-height: 700px;
      }
    }
  }

  @media (min-width: 1200px) {
    .ReactModal__Content {
      max-width: 970px;
    }

    .modal-content.modal-out {
      max-width: 1200px;
      padding: 50px 15px;
    }
  }
`;

export default function TermsOfUse() {
  // -- HTML Serializer
  const htmlSerializer = function (type, element, content, children, key) {
    switch (type) {
      case Elements.heading1: // Heading 1
        return <h1 key={key}>{children}</h1>

      case Elements.heading2: // Heading 2
        return <h2 key={key}>{children}</h2>

      case Elements.heading3: // Heading 3
        return <h3 key={key}>{children}</h3>

      case Elements.heading4: // Heading 4
        return <h4 key={key}>{children}</h4>

      case Elements.heading5: // Heading 5
        return <h5 key={key}>{children}</h5>

      case Elements.heading6: // Heading 6
        return <h6 key={key}>{children}</h6>

      case Elements.paragraph: // Paragraph
        return <p key={key}>{children}</p>

      case Elements.preformatted: // Preformatted
        return <pre key={key}>{children}</pre>

      case Elements.strong: // Strong
        return <strong key={key}>{children}</strong>

      case Elements.em: // Emphasis
        return <em key={key}>{children}</em>

      case Elements.listItem: // Unordered List Item
      case Elements.oListItem: // Ordered List Item
        return <li key={key}>{children}</li>

      case Elements.list: // Unordered List
        return <ul key={key}>{children}</ul>

      case Elements.oList: // Ordered List
        return <ol key={key}>{children}</ol>

      case Elements.image:
        const linkUrl = element.linkTo
            ? element.linkTo.url || linkResolver(element.linkTo)
            : null

        return (
            <p
                key={key}
                className={[element.label, 'block-img'].filter(Boolean).join(' ')}
            >
              {linkUrl ? (
                  <a
                      href={linkUrl}
                      target={element.linkTo?.target}
                      rel={element.linkTo?.target ? 'noopener' : undefined}
                  >
                    <img src={element.url} alt={element.alt || ''} />
                  </a>
              ) : (
                  <img src={element.url} alt={element.alt || ''} />
              )}
            </p>
        )

      case Elements.embed: // Embed
        return (
            <div
                key={key}
                data-oembed={element.oembed.embed_url}
                data-oembed-type={element.oembed.type}
                data-oembed-provider={element.oembed.provider_name}
                className={element.label}
                __dangerouslySetInnerHTML={{ __html: element.oembed.html }}
            />
        )

      case Elements.hyperlink: // Hyperlinks
        const url = PrismicLink.url(element.data, linkResolver)

        if (element.data.link_type === 'Document') {
          return (
              <Link key={key} to={url}>
                {content}
              </Link>
          )
        }

        return (
            <a
                key={key}
                href={url}
                target={element.data.target}
                rel={element.data.target ? 'noopener' : undefined}
                className="link-class"
            >
              {children}
            </a>
        )

      case Elements.label: // Label
        return (
            <label key={key} className={element.data.label}>
              {children}
            </label>
        )

      case Elements.span: // Span
        if (content) {
          return content.split('\\n').reduce((acc, p) => {
            if (acc.length === 0) {
              return [p]
            } else {
              const brIndex = (acc.length + 1) / 2 - 1
              return acc.concat([<br key={brIndex} />, p])
            }
          }, [])
        } else {
          return null
        }

      default:
        // Always include a default that returns null
        return null
    }
  }

  const results = useStaticQuery(graphql`
    {
      termos: allPrismicTermosDeUsoMeuAmbiente {
        edges {
          node {
            data {
              termos {
                text
                type
                spans {
                  type
                }
              }
            }
            uid
          }
        }
      }
    }
  `)
  return (

    <ModalRoutingContext.Consumer>

      {({ modal, closeTo }) => (
        <>
          {!modal && <Header />}
          <div className={!modal ? 'modal-background' : ''}>
            <div className={!modal ? 'modal-content modal-out' : 'modal-content'}>
              <div className="modal-header">
                <h2>Termo de Utilização</h2>
                {modal && (
                  <Link to={closeTo} className="close-modal">
                    <i></i>
                  </Link>
                )}
              </div>

              <div className="terms-content">
                <RichText render={results.termos.edges[0].node.data.termos} htmlSerializer={htmlSerializer} />
              </div>

              <GlobalStyleLocal />
            </div>
          </div>

          {!modal && (
            <>
              <Newsletter />
              <Footer />
            </>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  )
}